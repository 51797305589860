// privateRoute
import React from "react";
import {isExpired} from "react-jwt";
import {Navigate} from "react-router-dom";
import useDecodedToken from "../Utils/useDecodedToken";

const PrivateRoute = ({children, token}) => {
  // let userData = JSON.parse(localStorage.getItem("userData"));
  // if (userData?.access_token === null) {
  //     return <Navigate to="/" replace />
  // } else if (isExpired(userData?.access_token)) {
  //     return <Navigate to="/" replace />
  // } else {
  //     return children;
  // }
  const decodedToken = useDecodedToken();
  if (decodedToken) {
    return children;
  }
  <Navigate to="/" replace />;
};

export default PrivateRoute;
