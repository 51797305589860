import { Button, Card, Input, Skeleton, Spin, Steps } from "antd";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { Helmet } from "react-helmet";
import "./Dashboard.css";
import "./DashboardAi.css";
import "./AnswerPage.css";
import { HiMiniWallet } from "react-icons/hi2";
import PrimaryTranscript from "../../../Components/PrimaryTranscript";
import useAnswers from "../../../Hooks/useAnswers";
import ExpertTable from "../../../Components/ExpertTable";
import ExpertDetailsModal from "../../../Components/ExpertDetailsModal";
import useUser from "../../../Hooks/useUser";
import NewsTable from "../../../Components/NewsTable";
import { BsGlobe, BsSearch, BsStars } from "react-icons/bs";
import { FiBook, FiCalendar, FiFileText, FiUser } from "react-icons/fi";
import { setIsOpenCreatProject, setIsOpenProject } from "./Project.Slice";
import { useDispatch } from "react-redux";
import { setSelectedExpert } from "../../ExpertPage/Expert.Slice";
import { CreateProjectModal } from "../../../../components/CreateProjectModal";
import useProject from "../../../Hooks/useProject";
import DummyTable from "../../../Components/DummyTable";
import { useParams } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";

const AnswerPage = () => {
  const { spendCredits } = useUser();
  const { answer, answerExpert, answerTranscript, answerNews, isLoadingNews, isLoading, isLoadingExpert, isLoadingTranscript, current } = useAnswers()
  const { question } = useParams()
  const dispatch = useDispatch()
  const { mutateProjectList } = useProject();
  return (
    <>
      <Helmet>
        <title>{"Nextyn IQ - Dashboard"}</title>
      </Helmet>
      <div className="content-wrapper content-wrapper-ai bg-light content-body" id="main-screen-dashboard">
        {/* <div className="content-body"> */}
        <Row className="p-4">
          <Col md={6} className="answer-card-body p-0 m-0">
            <div className="answer_body_wrapper_left">
              <Card
                title={
                  <div className="d-flex align-items-center">
                    <HiMiniWallet className="me-2" />
                    <span>Answer</span>
                  </div>
                }
                styles={{
                  body: {
                    padding: "0px 15px 15px 15px",
                  },
                }}
              >
                <div>
                  {isLoading ? <div className="qna_body d-flex flex-column gap-2 align-items-center justify-content-center">
                    {/* <div className="question_body">
                      <Skeleton rootClassName="custom_skeleton" style={{ width: 700, margin: 0 }} active title={true} className="mb-0" paragraph={{
                        rows: 0, style: {
                          margin: 0
                        }
                      }}> <span>some dummy question</span></Skeleton>
                    </div>
                    <div className="answer_body">
                      <Skeleton
                        active
                        paragraph={{
                          rows: 6,
                        }}
                      />
                    </div> */}
                    <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
                    <span>Initiating query processing and moderation.</span>
                  </div> :
                    <div className="qna_body">
                      <div className="question_body">
                        <span>{question}</span>
                      </div>
                      <div className="answer_body">
                        {answer?.data?.gpt_ai?.split(":")?.[0]}
                        <ol style={{ lineHeight: '1.8', paddingLeft: '20px' }}>
                          {answer?.data?.gpt_ai?.split(/\d+\./)?.filter((text) => text?.trim())?.splice(1)?.map((expert, index) => (
                            <li key={index} style={{ marginBottom: '15px' }}>
                              <span style={{ fontWeight: 'bold' }}>{expert?.split(':')?.[0]?.trim()}:</span>
                              <span style={{ color: '#555' }}> {expert?.split(':')?.[1]?.trim()}</span>
                            </li>
                          ))}
                        </ol>
                      </div>
                    </div>}
                </div>
                <div className="">

                  <div className="follow_up_container">
                    <Input
                      placeholder="Date, just a click away..."
                      prefix={<BsSearch />}
                      suffix={<BsStars />}
                    />
                    {/* <GrAttachment /> Ask a follow-up */}
                  </div>
                </div>
              </Card>
            </div>

          </Col>
          <Col md={6} className="p-0 m-0">
            {/* {isLoading || isLoadingExpert || isLoadingNews || isLoadingTranscript ?
                <>
                  <div className="backdrop"></div>
                  <div className="step_loader">
                    <Steps
                      direction="vertical"
                      size="small"
                      className="custom_step_style"
                      current={current}
                      items={[
                        { title: 'Initiating query processing and moderation."', style: { color: "aqua" } },
                        { title: 'Conducting a semantic search and ranking.', style: { color: "aqua" } },
                        { title: 'LLM is generating your response.', style: { color: "aqua" } },
                        { title: 'Your personalized answer is ready!', style: { color: "aqua" } },
                      ]}
                    />
                  </div>
                </>
                : null} */}
            <div className="answer_body_wrapper">
              <div className={isLoading || isLoadingExpert || isLoadingNews || isLoadingTranscript ? "answer_body_sec_loading" : "answer_body_sec"}>
                <PrimaryTranscript
                  cls="answer_table"
                  loading={isLoading || isLoadingTranscript}
                  title={
                    <Skeleton loading={isLoadingTranscript} active title={true} className="mt-2 mb-0" paragraph={{ rows: 0 }}>
                      <div className="d-flex justify-content-between align-items-center">
                        <span className="d-flex align-items-center g-2 gap-2"><FiBook /> Primary Transcript</span>
                        <span className="result_override">
                          <Button className="result_button"
                            style={{
                              display: "inline-flex",
                              alignItems: "center",
                              justifyContent: "center",
                              whiteSpace: "nowrap",
                              cursor: "pointer",
                              fontSize: "12px",
                            }}

                          >
                            Request a custom transcript
                          </Button>
                        </span>
                      </div>
                    </Skeleton >
                  }
                  spendCredits={spendCredits}
                  data={answerTranscript?.data ? answerTranscript : { data: [1, 2, 3, 4] }}
                  type={"transcript"}
                />
                {/* <PrimaryTranscript
                    loading={isLoading || loading}
                    cls="answer_table"
                    title={
                      <Skeleton loading={loading} active title={true} className="mt-2 mb-0" paragraph={{ rows: 0 }}>
                        <span>Secondary Research Reports</span>
                        <small className="text-muted ms-2">
                          (28 Secondary research reports found)
                        </small>
                      </Skeleton >
                    }
                    spendCredits={spendCredits}
                    type={"expert"}
                  /> */}
                <ExpertTable
                  loading={isLoading || isLoadingExpert}
                  data={answerExpert?.data ? answerExpert : { data: [1, 2, 3, 4] }}
                  cls="answer_table"
                  title={<Skeleton loading={isLoadingExpert} active title={true} className="mt-2 mb-0" paragraph={{ rows: 0 }}>
                    <div className="d-flex justify-content-between align-items-center">
                      <span className="d-flex align-items-center g-2 gap-2"><FiUser /> Experts Profiles</span>
                      <span className="result_override">
                        <Button className="result_button"
                          style={{
                            display: "inline-flex",
                            alignItems: "center",
                            justifyContent: "center",
                            whiteSpace: "nowrap",
                            cursor: "pointer",
                            fontSize: "12px",
                          }}
                          onClick={() => {
                            dispatch(setIsOpenCreatProject(true));
                            dispatch(setIsOpenProject(false));
                            dispatch(setSelectedExpert({}));
                          }}
                        >
                          Find me Experts
                        </Button>
                      </span>
                    </div>
                  </Skeleton >}
                  type={"experts"}
                />
                <NewsTable
                  loading={isLoading || isLoadingNews}
                  data={answerNews?.data ? answerNews : { data: [1, 2, 3, 4] }}
                  title={<Skeleton loading={isLoadingNews} active title={true} className="mt-2 mb-0" paragraph={{ rows: 0 }}>
                    <div className="d-flex justify-content-between align-items-center">
                      <span className="d-flex align-items-center g-2 gap-2"><BsGlobe /> News</span>
                      <span className="result_override">
                        {/* <Button className="result_button"
                            style={{
                              display: "inline-flex",
                              alignItems: "center",
                              justifyContent: "center",
                              whiteSpace: "nowrap",
                              cursor: "pointer",
                              fontSize: "12px",
                            }}

                          >
                            Find me News
                          </Button> */}
                      </span>
                    </div>
                  </Skeleton >}
                  cls="answer_table"
                  type={"news"}
                />
                <DummyTable
                  cls="answer_table"
                  loading={isLoading || isLoadingTranscript}
                  title={
                    <Skeleton loading={isLoadingTranscript} active title={true} className="mt-2 mb-0" paragraph={{ rows: 0 }}>
                      <div className="d-flex justify-content-between align-items-center">
                        <span className="d-flex align-items-center g-2 gap-2"><FiCalendar /> Company Docs Data</span>
                        <span className="result_override">
                          {/* <Button className="result_button"
                              style={{
                                display: "inline-flex",
                                alignItems: "center",
                                justifyContent: "center",
                                whiteSpace: "nowrap",
                                cursor: "pointer",
                                fontSize: "12px",
                              }}

                            >
                              Explore Data
                            </Button> */}
                        </span>
                      </div>
                    </Skeleton >
                  }
                  type={"transcript"}
                />
                <DummyTable
                  cls="answer_table"
                  loading={isLoading || isLoadingTranscript}
                  title={
                    <Skeleton loading={isLoadingTranscript} active title={true} className="mt-2 mb-0" paragraph={{ rows: 0 }}>
                      <div className="d-flex justify-content-between align-items-center">
                        <span className="d-flex align-items-center g-2 gap-2"><FiFileText /> Secondary Research Report</span>
                        <span className="result_override">
                          <Button className="result_button"
                            style={{
                              display: "inline-flex",
                              alignItems: "center",
                              justifyContent: "center",
                              whiteSpace: "nowrap",
                              cursor: "pointer",
                              fontSize: "12px",
                            }}
                          >
                            Search Report
                          </Button>
                        </span>
                      </div>
                    </Skeleton >
                  }
                  type={"transcript"}
                />
                {/* <ExpertAvatarCard title={"Expert Profiles"} loading={loading} /> */}
              </div>
            </div>
          </Col>
        </Row>
      </div>
      {/* </div> */}
      <ExpertDetailsModal />
      <CreateProjectModal
        buttonText="Add Project"
        mutateProjectList={mutateProjectList}
      />
    </>
  );
};

export default AnswerPage;
