import { Card, Popover, Skeleton, Spin, Tooltip } from "antd";
import React from "react";
import { Col, Row } from "react-bootstrap";
import TranscriptPopOver from "./TranscriptPopOver";
import useProject from "../Hooks/useProject";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { clearChatMessages, setSelectedTranscript } from "../Pages/Transcript/TranscriptChat.slice";
import UnlockTranscriptModal from "./UnlockTranscriptModal";
import useUser from "../Hooks/useUser";
import UnlockButton from "./UnlockButton";
import { LoadingOutlined } from "@ant-design/icons";

function PrimaryTranscript({ title, cls, loading, spendCredits, data = { data: [1, 2, 3, 4] } }) {

  const userData = JSON.parse(localStorage.getItem("userData"));
  const [hoveredItemId, setHoveredItemId] = React.useState(null);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [selected, setSelected] = React.useState({});
  const creditsData = useSelector((state) => state?.user?.all_credits);
  const { projectListById } = useSelector((state) => state.project);
  const history = useNavigate();
  const dispatch = useDispatch();


  const permission = React.useMemo(
    () => userData?.user?.role_id,
    [userData?.user?.role_id]
  );
  const { AddExpertProject, getProjectListById, mutateProjectList } = useProject();

  const handleCancel = React.useCallback(() => {
    setIsModalOpen(false);
  }, []);

  const capitalizeFirstLetter = React.useCallback((string) => {
    if (!string) return "";
    return string.charAt(0).toUpperCase() + string.slice(1);
  }, []);

  const goToDetailsPage = React.useCallback(
    (curElem) => {
      history({
        pathname: `/transcript-details/${curElem?._id}`,
        state: { curElem },
      });
    },
    [history]
  );

  const handleSpendCredit = React.useCallback(
    async (id, category) => {
      let res = await spendCredits({
        id,
        category,
        credits_spend: creditsData?.credits_for_transcript,
        navigation: false,
      });
      return res;
    },
    [creditsData?.credits_for_transcript, spendCredits]
  );
  const showModal = React.useCallback((elem) => {
    setSelected(elem);
    setIsModalOpen(true);
  }, []);
  const handleClickTranscript = React.useCallback((curElem) => {
    if (curElem?.unlock === true) {
      if (permission?.transcript?.view) {
        goToDetailsPage(curElem);
        dispatch(clearChatMessages());
      }
    } else if (hoveredItemId === curElem?._id && permission?.transcript?.unlock) {
      dispatch(clearChatMessages());
      showModal(curElem);
    }
  }, [dispatch, goToDetailsPage, hoveredItemId, permission?.transcript?.unlock, permission?.transcript?.view, showModal])

  return (
    <div className={`${cls}`}>
      <Card
        className="mb-2"
        styles={{
          header: {
            border: "none",
            padding: "15px 15px 8px 15px",
          },
          title: {
            padding: "0px",
          },
        }}
        title={<div>{title}</div>}
      >
        <div className="ctm_header mt-2">
          <Row>
            <Col xs={4} >
              <Skeleton rootClassName="custom_skeleton" loading={loading} style={{ width: 400, margin: 0 }} active title={true} className="mb-0" paragraph={{
                rows: 0, style: {
                  margin: 0
                }
              }}> <div>Transcript Title</div></Skeleton>
            </Col>
            <Col xs={3}>
              <Skeleton loading={loading} style={{ width: 300 }} active title={true} className=" mb-0" paragraph={{
                rows: 0, style: {
                  margin: 0
                }
              }}><div>Expert title</div></Skeleton>
            </Col>
            <Col xs={3}>
              <Skeleton loading={loading} style={{ width: 300 }} active title={true} className=" mb-0" paragraph={{
                rows: 0, style: {
                  margin: 0
                }
              }}><div>Expert company</div></Skeleton>
            </Col>
            <Col xs={2}>
              <Skeleton loading={loading} style={{ width: 300 }} active title={true} className=" mb-0" paragraph={{
                rows: 0, style: {
                  margin: 0
                }
              }}><div>Date</div></Skeleton>
            </Col>
            {/* <Col xs={2}>
              <Skeleton loading={loading} style={{ width: 300 }} active title={true} className=" mb-0" paragraph={{
                rows: 0, style: {
                  margin: 0
                }
              }}><div>Credits</div></Skeleton>
            </Col> */}
          </Row>
        </div>
        <div className="ctm_body">
          {loading && <div className="d-flex flex-column justify-content-center align-items-center" style={{ height: 100 }}>
            <Spin indicator={<LoadingOutlined style={{ fontSize: 30 }} spin />} />
            <span>Initiating query processing and moderation.</span>
          </div>}
          {!loading && data?.data?.slice(0, 4)?.map((curElem) => (
            <Row>
              <Col xs={4} onMouseEnter={() => setHoveredItemId(curElem?._id)}
                onMouseLeave={() => setHoveredItemId(null)}
                onClick={() => handleClickTranscript(curElem)}>
                <Skeleton loading={loading} style={{ width: 400 }} active title={true} className="mb-0" paragraph={{
                  rows: 0, style: {
                    margin: 0
                  }
                }}>  <Popover
                  placement="left"
                  content={
                    <TranscriptPopOver
                      curElem={curElem}
                      functions={{
                        AddExpertProject,
                        getProjectListById,
                        mutateProjectList,
                      }}
                      dataVariables={{ projectListById }}
                    />
                  }
                >
                    <Tooltip title={capitalizeFirstLetter(curElem?.topic)}>
                      <p
                        className="fw-bolder truncate-text mb-0"
                        style={{ fontSize: "12px", cursor: "pointer" }}
                        onClick={() => {
                          if (curElem?.unlock === true) {
                            if (permission?.transcript?.view) {
                              goToDetailsPage(curElem);
                              dispatch(clearChatMessages());
                            }
                          } else {
                            if (permission?.transcript?.unlock) {
                              dispatch(clearChatMessages());
                              showModal(curElem);
                            }
                          }
                        }}
                      >
                        {capitalizeFirstLetter(curElem?.topic)}
                      </p>
                    </Tooltip>
                  </Popover></Skeleton>
              </Col>
              <Col xs={3} onMouseEnter={() => setHoveredItemId(curElem?._id)}
                onMouseLeave={() => setHoveredItemId(null)}
                onClick={() => handleClickTranscript(curElem)}>
                <Skeleton loading={loading} style={{ width: 300 }} active title={true} className="mb-0" paragraph={{
                  rows: 0, style: {
                    margin: 0
                  }
                }}> <div>After Sales Manager</div></Skeleton>
              </Col>
              <Col xs={3} onMouseEnter={() => setHoveredItemId(curElem?._id)}
                onMouseLeave={() => setHoveredItemId(null)}
                onClick={() => handleClickTranscript(curElem)}>
                <Skeleton loading={loading} style={{ width: 300 }} active title={true} className="mb-0" paragraph={{
                  rows: 0, style: {
                    margin: 0
                  }
                }}>  <div>Cummies Alberia</div></Skeleton>
              </Col>
              <Col xs={2} onMouseEnter={() => setHoveredItemId(curElem?._id)}
                onMouseLeave={() => setHoveredItemId(null)}
                onClick={() => handleClickTranscript(curElem)}>
                <Skeleton loading={loading} style={{ width: 300 }} active title={true} className="mb-0" paragraph={{
                  rows: 0, style: {
                    margin: 0
                  }
                }}>  <div>26 Dec 2024</div></Skeleton>
              </Col>
              {/* <Col xs={2}
                onMouseEnter={() => setHoveredItemId(curElem?._id)}
                onMouseLeave={() => setHoveredItemId(null)}>
                <Skeleton loading={loading} style={{ width: 300 }} active title={true} className="mb-0" paragraph={{
                  rows: 0, style: {
                    margin: 0
                  }
                }}>  <UnlockButton
                    data={curElem}
                    hoveredItemId={hoveredItemId}
                    unlockFunction={() => {
                      dispatch(clearChatMessages());
                      showModal(curElem);
                    }}
                  /></Skeleton>
              </Col> */}
            </Row>
          ))}
          {data?.data?.length === 0 && !loading && <div className="d-flex justify-content-center align-items-center" style={{ height: 100 }}>
            <span>No Transcripts Found  Related to search</span>
          </div>}
        </div>
      </Card>

      <UnlockTranscriptModal
        handleCancel={handleCancel}
        isModalOpen={isModalOpen}
        unlockFunction={async () => {
          try {
            let response = await handleSpendCredit(
              selected?._id,
              "transcript"
            );
            if (response) {
              goToDetailsPage(selected);
              dispatch(setSelectedTranscript(selected));
            }
            setIsModalOpen(false);
            return response
          } catch (error) {
            return error
          }

        }}

      />
    </div>
  );
}

export default PrimaryTranscript;
