import { Card, Skeleton, Spin, Tooltip } from "antd";
import React, { useCallback } from "react";
import { Col, Row } from "react-bootstrap";
import { checkForValidURL } from "../../Utils/Util";
import { setExpertProfile, setSelectedExpert } from "../Pages/ExpertPage/Expert.Slice";
import { setToggle } from "../Pages/V2/Dashboard/dashboard.slice";
import { useDispatch } from "react-redux";
import axios from "axios";
import API_URL from "../../Constants/api-path";
import { LoadingOutlined } from "@ant-design/icons";

function ExpertTable({ title, cls, loading, data = { data: [1, 2, 3, 4] } }) {
    const [hoveredItemId, setHoveredItemId] = React.useState(null);
    const dispatch = useDispatch()
    const userData = JSON.parse(localStorage.getItem("userData"));
    const permission = React.useMemo(() => userData?.user?.role_id, [userData?.user?.role_id]);
    const getExpertById = useCallback(
        async (id) => {
            try {
                const response = await axios.get(
                    API_URL.GET_EXPERT_DATA_BY_ID + `?expert_id=${id}`
                );
                dispatch(setExpertProfile(response?.data?.data));
            } catch (error) {
                console.error("ERROR", error);
            }
        },
        [dispatch]
    );

    const handleClickExpert = React.useCallback((data) => {
        if (hoveredItemId === data?._id && permission?.experts?.view) {
            getExpertById(data?._id);
            dispatch(setToggle(true));
            dispatch(setSelectedExpert(data));
        }
    }, [dispatch, getExpertById, hoveredItemId, permission?.experts?.view])

    return (
        <div className={`${cls}`}>
            <Card
                className="mb-2"
                styles={{
                    header: {
                        border: "none",
                        padding: "15px 15px 8px 15px",
                    },
                    title: {
                        padding: "0px",
                    },
                }}
                title={<div>{title}</div>}
            >
                <div className="ctm_header mt-2">
                    <Row>
                        <Col xs={4} >
                            <Skeleton rootClassName="custom_skeleton" loading={loading} style={{ width: 400, margin: 0 }} active title={true} className="mb-0" paragraph={{
                                rows: 0, style: {
                                    margin: 0
                                }
                            }}> <div>Designation</div></Skeleton>
                        </Col>
                        <Col xs={3}>
                            <Skeleton loading={loading} style={{ width: 300 }} active title={true} className=" mb-0" paragraph={{
                                rows: 0, style: {
                                    margin: 0
                                }
                            }}><div>Company</div></Skeleton>
                        </Col>
                        <Col xs={3}>
                            <Skeleton loading={loading} style={{ width: 300 }} active title={true} className=" mb-0" paragraph={{
                                rows: 0, style: {
                                    margin: 0
                                }
                            }}><div>Previous Company</div></Skeleton>
                        </Col>
                        <Col xs={2}>
                            <Skeleton loading={loading} style={{ width: 300 }} active title={true} className=" mb-0" paragraph={{
                                rows: 0, style: {
                                    margin: 0
                                }
                            }}><div>Country</div></Skeleton>
                        </Col>
                        {/* <Col xs={2}>
                            <Skeleton loading={loading} style={{ width: 300 }} active title={true} className=" mb-0" paragraph={{
                                rows: 0, style: {
                                    margin: 0
                                }
                            }}><div>Credits</div></Skeleton>
                        </Col> */}
                    </Row>
                </div>
                <div className="ctm_body">
                    {loading && <div className="d-flex flex-column justify-content-center align-items-center" style={{ height: 100 }}>
                        <Spin indicator={<LoadingOutlined style={{ fontSize: 30 }} spin />} />
                        <span>LLM is generating your response.</span>
                    </div>}
                    {!loading && data?.data?.slice(0, 4)?.map((data, id) => (
                        <Row key={id}>
                            <Col xs={4} onMouseEnter={() => setHoveredItemId(data?._id)}
                                onMouseLeave={() => setHoveredItemId(null)}
                                onClick={() => handleClickExpert(data)}>
                                <Skeleton loading={loading} style={{ width: 400 }} active title={true} className="mb-0" paragraph={{
                                    rows: 0, style: {
                                        margin: 0
                                    }
                                }}> <Tooltip title={data?.currunt_designation}>
                                        <strong className="fw-normal truncate-text">
                                            {data?.currunt_designation || "-"}
                                        </strong>
                                    </Tooltip></Skeleton>
                            </Col>
                            <Col xs={3} onMouseEnter={() => setHoveredItemId(data?._id)}
                                onMouseLeave={() => setHoveredItemId(null)}
                                onClick={() => handleClickExpert(data)}>
                                <Skeleton loading={loading} style={{ width: 300 }} active title={true} className="mb-0" paragraph={{
                                    rows: 0, style: {
                                        margin: 0
                                    }
                                }}> <span className="d-flex align-items-center gap-2">
                                        <span>
                                            <img
                                                src={
                                                    checkForValidURL(data?.current_experience?.[0]?.logo_url)
                                                }
                                                alt="logo"
                                                height={"25px"}
                                                width={"25px"}
                                            />
                                        </span>
                                        <Tooltip title={data?.currunt_company}>
                                            <small className="fw-bolder truncate-text">
                                                {data?.currunt_company || "-"}
                                            </small>
                                        </Tooltip>
                                    </span></Skeleton>
                            </Col>
                            <Col xs={3} onMouseEnter={() => setHoveredItemId(data?._id)}
                                onMouseLeave={() => setHoveredItemId(null)}
                                onClick={() => handleClickExpert(data)}>
                                <Skeleton loading={loading} style={{ width: 300 }} active title={true} className="mb-0" paragraph={{
                                    rows: 0, style: {
                                        margin: 0
                                    }
                                }}>   <Tooltip
                                    title={
                                        data?.previous_company?.split("+")[1]
                                            ? data?.previous_company?.split("+")[1]
                                            : data?.previous_company?.split("+")[0]
                                    }
                                >
                                        <span className="d-flex align-items-center gap-2">
                                            {(data?.previous_company?.split("+")[1] ||
                                                data?.previous_company?.split("+")[0]) && (
                                                    <span>
                                                        <img
                                                            src={checkForValidURL(data?.previous_company_logo)}
                                                            alt="logo"
                                                            height={"25px"}
                                                            width={"25px"}

                                                        />
                                                    </span>
                                                )}
                                            <small className="fw-normal truncate-text">
                                                {data?.previous_company?.split("+")[1]
                                                    ? data?.previous_company?.split("+")[1]
                                                    : data?.previous_company?.split("+")[0] || "-"}
                                            </small>
                                        </span>
                                    </Tooltip></Skeleton>
                            </Col>
                            <Col xs={2} onMouseEnter={() => setHoveredItemId(data?._id)}
                                onMouseLeave={() => setHoveredItemId(null)}
                                onClick={() => handleClickExpert(data)}>
                                <Skeleton loading={loading} style={{ width: 300 }} active title={true} className="mb-0" paragraph={{
                                    rows: 0, style: {
                                        margin: 0
                                    }
                                }}>    <Tooltip title={data?.country || "-"}>
                                        <small className="fw-normal truncate-text">
                                            {data?.country || "-"}
                                        </small>
                                    </Tooltip></Skeleton>
                            </Col>
                            {/* <Col xs={2} onMouseEnter={() => setHoveredItemId(data?._id)}
                                onMouseLeave={() => setHoveredItemId(null)}>
                                <Skeleton loading={loading} style={{ width: 300 }} active title={true} className="mb-0" paragraph={{
                                    rows: 0, style: {
                                        margin: 0
                                    }
                                }}>     <UnlockExpertButton
                                        data={data}
                                        hoveredItemId={hoveredItemId}
                                        unlockFunction={() => {
                                            getExpertById(data?._id);
                                            dispatch(setToggle(true));
                                            dispatch(setSelectedExpert(data));
                                        }}
                                    /></Skeleton>
                            </Col> */}
                        </Row>
                    ))}

                    {!loading && data?.data?.length === 0 && <div className="d-flex justify-content-center align-items-center" style={{ height: 100 }}>
                        <span>No Experts Found  Related to search</span>
                    </div>}
                </div>
            </Card>
        </div>
    );
}

export default ExpertTable;
