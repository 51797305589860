import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    answer: {},
    answerTranscript: {},
    answerExpert: {},
    answerNews: {},
    current: 0
}

const answerSlice = createSlice({
    initialState: initialState,
    name: "ProjectListById",
    reducers: {
        setAnswer: (state, { payload }) => {
            state.answer = payload
        },
        setTranscriptAnswer: (state, { payload }) => {
            state.answerTranscript = payload
        },
        setExpertAnswer: (state, { payload }) => {
            state.answerExpert = payload
        },
        setNewsAnswer: (state, { payload }) => {
            state.answerNews = payload
        },
        setCurrent: (state, { payload }) => {
            state.current = payload
        },
        resetAnswer: (state) => {
            state.answer = {}
            state.answerTranscript = {}
            state.answerExpert = {}
            state.answerNews = {}
            state.current = 0
        }
    }
})
export const answerReducer = answerSlice.reducer
export const { setAnswer, setTranscriptAnswer, setExpertAnswer, setNewsAnswer, resetAnswer, setCurrent } = answerSlice.actions