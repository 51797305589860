// privateRoute
import React from "react";
// import { isExpired } from 'react-jwt';
import {Navigate} from "react-router-dom";
import useDecodedToken from "../Utils/useDecodedToken";

const PrivateRouteAuth = ({children}) => {
  // let userData = JSON.parse(localStorage.getItem("userData"));
  const decodedToken = useDecodedToken();
  if (decodedToken) {
    return <Navigate to="/dashboard" replace />;
  }
  return children;

  // if (userData?.access_token === null) {
  //     return <Navigate to="/" replace />
  // } else if (!isExpired(userData?.access_token)) {
  //     return <Navigate to="/dashboard" replace />
  // }
  // return children;
};

export default PrivateRouteAuth;
