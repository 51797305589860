import React from 'react';
import { Modal } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';

const SuccessModalNew = ({ open, setOpen }) => {
    return (
        <Modal
            title={""}
            open={open}
            centered
            onOk={() => {
                setOpen(false)
            }}
            onCancel={() => {
                setOpen(false)
            }}
            footer={false}

        >
            <div className='d-flex flex-column justify-content-center align-items-center p-4'>
                <CheckCircleOutlined style={{
                    fontSize: 48,
                    color: "green"
                }} />
                <p className='fs-5'>Request sent Successfully!</p>
            </div>

        </Modal>
    );
};
export default SuccessModalNew;
