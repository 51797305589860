import axios from 'axios'
import { useCallback, useMemo } from 'react'
import { useParams, } from 'react-router-dom'
import useSWR from 'swr'
import API_URL from '../../Constants/api-path'
import { useDispatch, useSelector } from 'react-redux'
import { setAnswer, setCurrent, setExpertAnswer, setNewsAnswer, setTranscriptAnswer } from '../Pages/V2/Dashboard/Answer.slice'

const useAnswers = () => {
    const { question } = useParams()
    const userData = JSON.parse(localStorage.getItem("userData"));

    const dispatch = useDispatch()
    const { answer, answerTranscript, answerNews, answerExpert, current } = useSelector((state) => state.answer)

    const getGptAnswer = useCallback(async () => {
        const res = await axios.get(API_URL.GPT_ANSWER + `?user_id=${userData?.user?._id}&question=${question}`)
        return res.data
    }, [question, userData?.user?._id])
    const getGptTranscriptAnswer = useCallback(async () => {
        const res = await axios.get(API_URL.GPT_ANSWER_TRANSCRIPT + `?chat_id=${answer?.chat_id || "6746cfb06ec03b3604ad98a3"}`)
        return res.data
    }, [answer?.chat_id])
    const getGptExpertAnswer = useCallback(async () => {
        const res = await axios.get(API_URL.GPT_ANSWER_EXPERT + `?chat_id=${answer?.chat_id}`)
        return res.data
    }, [answer?.chat_id])
    const getGptNewsAnswer = useCallback(async () => {
        const res = await axios.get(API_URL.GPT_ANSWER_NEWS + `?chat_id=${answer?.chat_id || "6748186e035285f9d5a81ccd"}`)
        return res.data
    }, [answer?.chat_id])
    const someFunction = () => {
        console.info('question: ', question);
    }
    const { isLoading } = useSWR(["get_gpt_answer", question], () => question ? getGptAnswer() : someFunction(), {
        revalidateOnMount: true,
        revalidateOnFocus: false,
        shouldRetryOnError: false,
        onSuccess: (res) => {
            if (res) {
                dispatch(setCurrent(current + 1))
                dispatch(setAnswer(res))
            }
        },
        onError: (err) => {
            dispatch(setCurrent(current + 1))
            console.error("ERROR", err)
        }
    })

    const { isLoading: isLoadingTranscript } = useSWR(["get_gpt_transcript_answer", answer?.chat_id], () => answer?.chat_id ? getGptTranscriptAnswer() : someFunction(), {
        revalidateOnMount: true,
        revalidateOnFocus: false,
        shouldRetryOnError: false,
        onSuccess: (res) => {
            if (res) {
                dispatch(setCurrent(current + 1))
                dispatch(setTranscriptAnswer(res))
            }
        },
        onError: (err) => {
            dispatch(setCurrent(current + 1))
            console.error("ERROR", err)
        }
    })
    const { isLoading: isLoadingExpert } = useSWR(["get_gpt_expert_answer", answer?.chat_id], () => answer?.chat_id ? getGptExpertAnswer() : someFunction(), {
        revalidateOnMount: true,
        revalidateOnFocus: false,
        shouldRetryOnError: false,
        onSuccess: (res) => {
            if (res) {
                dispatch(setCurrent(current + 1))
                dispatch(setExpertAnswer(res))
            }
        },
        onError: (err) => {
            dispatch(setCurrent(current + 1))
            console.error("ERROR", err)
        }
    })
    const { isLoading: isLoadingNews } = useSWR(["get_gpt_news_answer", answer?.chat_id], () => answer?.chat_id ? getGptNewsAnswer() : someFunction(), {
        revalidateOnMount: true,
        revalidateOnFocus: false,
        shouldRetryOnError: false,
        onSuccess: (res) => {
            if (res) {
                dispatch(setCurrent(current + 1))
                dispatch(setNewsAnswer(res))
            }
        },
        onError: (err) => {
            dispatch(setCurrent(current + 1))
            console.error("ERROR", err)
        }
    })

    const values = useMemo(() => ({
        answer, isLoading, isLoadingTranscript, isLoadingExpert, answerTranscript, answerExpert, isLoadingNews, answerNews, current
    }), [answer, isLoading, isLoadingTranscript, isLoadingExpert, answerTranscript, answerExpert, isLoadingNews, answerNews, current])
    return values
}

export default useAnswers
