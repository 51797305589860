import React, { useState } from "react";
import { Button, Input, Spin, Modal, Divider } from "antd";
import "react-datepicker/dist/react-datepicker.css";
import "../App.css";
import { Col, Row } from "react-bootstrap";
import { FaCheckCircle } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
// import {
//   setIsOpenCreatProject,
//   setIsOpenProject,
// } from "../NewDesign/Pages/V2/Dashboard/Project.Slice";
import { LoadingOutlined } from "@ant-design/icons";
import _ from "lodash";
import { setIsOpenCreatProject, setIsOpenProject } from "../NewDesign/Pages/V2/Dashboard/Project.Slice";

const { Search } = Input;

export const ProjectListModal = ({
  selectedExpertId,
  functions,
  isLoading,
  country
}) => {
  const { projectListById, AddExpertProject, getProjectListById, setOpen } = functions;
  const { isOpenProject } = useSelector((state) => state.project);
  const [selectedProjectId, setSelectedProjectId] = useState(null);
  const creditsData = useSelector((state) => state?.user?.all_credits);

  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(setIsOpenProject(false));
    setSelectedProjectId(null);
  };
  const handleSelectProject = (projectId) => setSelectedProjectId(projectId);
  // const onSearch = (event) => {
  //   const value = event.target.value;

  //   getProjectListById(value);
  // };

  const onSearch = _.debounce((e) => {
    const value = e.target.value;
    getProjectListById(value);
  }, 500)

  const handleSubmit = async () => {
    setLoading(true);
    const response = await AddExpertProject({
      project_id: selectedProjectId,
      expert_id: selectedExpertId,
    });
    if (response?.success) {
      setLoading(false);
      dispatch(setIsOpenProject(false));
      setSelectedProjectId(null);
    }
  };

  // if (first) {
  return (
    <Modal
      width={840}
      centered
      title={
        <div className="text-start">
          <h4 className="modalHeader mb-2">Projects</h4>
          <p class=" text-muted" style={{ fontSize: "13px" }}>
            Add this expert to an existing project or create a new project
            by clicking on the "Add Project" button below.
          </p>
          <p class="text-muted" style={{ fontSize: "13px" }}>
            {country?.trim()?.toLowerCase() === "india" ? creditsData?.credits_for_expert : creditsData?.credits_for_premium_expert} credits will be deducted when
            an expert is requested. The credits will be refunded if the call
            does not get scheduled.
          </p>
        </div>}
      open={isOpenProject}
      onCancel={handleClose}

      footer={
        <div className="d-flex justify-content-between align-items-center">
          <div className="gap-2 g-2">
            <Button
              className="btn viewButton text-end me-2"
              style={{ height: "32px" }}
              onClick={() => {
                dispatch(setIsOpenCreatProject(true));
                dispatch(setIsOpenProject(false));
                // closeProjectModal(false)
              }}
            >
              {"Add Project"}
            </Button>
            <Button
              className="btn viewButton text-end"
              style={{ height: "32px" }}
              onClick={() => {
                dispatch(setIsOpenProject(false));
                setOpen(true)
              }}
            >
              {"Request this expert"}
            </Button>
          </div>
          <div>
            <Button
              className="me-2"
              key="back"
              onClick={() => {
                handleClose();
              }}
            >
              Cancel
            </Button>
            <Button
              key="submit"
              disabled={!selectedProjectId}
              style={{
                backgroundColor: "rgb(76, 187, 23)",
                borderColor: "rgb(76, 187, 23)",
                color: "#fff",
              }}
              onClick={() => handleSubmit()}
            >
              Submit{" "}
              <Spin
                spinning={loading}
                className="ms-2"
                size="small"
                indicator={<LoadingOutlined />}
              />
            </Button>
          </div>
        </div>

      }

    >
      <Divider style={{ marginBlock: 10 }} />
      <Row>
        <Col md={12} className="mb-2">
          <Search
            placeholder="Search existing projects"
            onChange={onSearch}
            style={{
              width: "100%",
            }}
          />
        </Col>
        <Col md={12}>
          {!isLoading &&
            projectListById?.data?.length > 0 &&
            projectListById?.data?.map((project) => (
              <div
                key={project?._id}
                style={{
                  cursor: "pointer",
                  backgroundColor:
                    selectedProjectId === project?._id
                      ? "rgba(0, 0, 0, 0.04)"
                      : "transparent",
                  color:
                    selectedProjectId === project?._id ? "#000" : "black",
                  fontSize: "12px",
                }}
                onClick={() => handleSelectProject(project?._id)}
                className="d-flex justify-content-between align-items-center p-1 fw-bolder"
              >
                {project?.project_title}
                {selectedProjectId === project?._id && (
                  <span style={{ color: "green" }}>
                    <FaCheckCircle />
                  </span>
                )}
              </div>
            ))}
          {isLoading && (
            <div
              style={{
                cursor: "pointer",
                backgroundColor: "transparent",
                color: "black",
                fontSize: "12px",
              }}
              className="d-flex justify-content-center align-items-center p-1 fw-bolder"
            >
              <Spin size="large" />
            </div>
          )}
        </Col>
      </Row>
      <Divider style={{ marginBlock: 10 }} />
    </Modal>
  )
  // }
  // return (
  //   <>
  //     <Modal
  //       show={isOpenProject}
  //       onHide={handleClose}
  //       backdrop={"static"}
  //       keyboard={false}
  //       centered
  //       size={"lg"}
  //       scrollable={true}
  //       dialogClassName={"bootStrapModal"}
  //       contentClassName={"bootStrapModalContent"}
  //     >
  //       <Modal.Header>
  //         <Modal.Title>
  //           <div
  //             className="position-absolute m-0"
  //             style={{ right: "15px", fontSize: "25px" }}
  //           >
  //             <MdOutlineClose onClick={handleClose} />
  //           </div>

  //           <div className="text-start">
  //             <h4 className="modalHeader mb-2">Projects</h4>
  //             <p class=" text-muted" style={{ fontSize: "13px" }}>
  //               Add this expert to an existing project or create a new project
  //               by clicking on the "Add Project" button below.
  //             </p>
  //             <p class="text-muted" style={{ fontSize: "13px" }}>
  //               {creditsData?.credits_for_expert} credits will be deducted when
  //               an expert is requested. The credits will be refunded if the call
  //               does not get scheduled.
  //             </p>
  //           </div>
  //         </Modal.Title>
  //       </Modal.Header>
  //       <Modal.Body className="position-relative">
  //         <Row>
  //           <Col md={12} className="mb-2">
  //             <Search
  //               placeholder="Search existing projects"
  //               onChange={onSearch}
  //               style={{
  //                 width: "100%",
  //               }}
  //             />
  //           </Col>
  //           <Col md={12}>
  //             {!isLoading &&
  //               projectListById?.data?.length > 0 &&
  //               projectListById?.data?.map((project) => (
  //                 <div
  //                   key={project?._id}
  //                   style={{
  //                     cursor: "pointer",
  //                     backgroundColor:
  //                       selectedProjectId === project?._id
  //                         ? "rgba(0, 0, 0, 0.04)"
  //                         : "transparent",
  //                     color:
  //                       selectedProjectId === project?._id ? "#000" : "black",
  //                     fontSize: "12px",
  //                   }}
  //                   onClick={() => handleSelectProject(project?._id)}
  //                   className="d-flex justify-content-between align-items-center p-1 fw-bolder"
  //                 >
  //                   {project?.project_title}
  //                   {selectedProjectId === project?._id && (
  //                     <span style={{ color: "green" }}>
  //                       <FaCheckCircle />
  //                     </span>
  //                   )}
  //                 </div>
  //               ))}
  //             {isLoading && (
  //               <div
  //                 style={{
  //                   cursor: "pointer",
  //                   backgroundColor: "transparent",
  //                   color: "black",
  //                   fontSize: "12px",
  //                 }}
  //                 className="d-flex justify-content-center align-items-center p-1 fw-bolder"
  //               >
  //                 <Spin size="large" />
  //               </div>
  //             )}
  //           </Col>
  //         </Row>
  //       </Modal.Body>
  //       <Modal.Footer className="d-flex justify-content-between align-items-center">
  //         <Button
  //           className="btn viewButton text-end"
  //           style={{ height: "32px" }}
  //           onClick={() => {
  //             dispatch(setIsOpenCreatProject(true));
  //             dispatch(setIsOpenProject(false));
  //             // closeProjectModal(false)
  //           }}
  //         >
  //           {"Add Project"}
  //         </Button>
  //         <div>
  //           <Button
  //             className="me-2"
  //             key="back"
  //             onClick={() => {
  //               handleClose();
  //             }}
  //           >
  //             Cancel
  //           </Button>
  //           <Button
  //             key="submit"
  //             disabled={!selectedProjectId}
  //             style={{
  //               backgroundColor: "rgb(76, 187, 23)",
  //               borderColor: "rgb(76, 187, 23)",
  //               color: "#fff",
  //             }}
  //             onClick={() => handleSubmit()}
  //           >
  //             Submit{" "}
  //             <Spin
  //               spinning={loading}
  //               className="ms-2"
  //               size="small"
  //               indicator={<LoadingOutlined />}
  //             />
  //           </Button>
  //         </div>
  //       </Modal.Footer>
  //     </Modal>
  //     {/* <button
  //               className="btn viewButton text-end"
  //               style={{
  //                   fontSize: "12px",
  //                   padding: buttonText === "Add Projects" ? "8px 16px" : "",
  //               }}
  //               onClick={() => {
  //                   dispatch(setIsOpenProject(true))
  //               }}>
  //               {" "}
  //               Request
  //           </button>{" "} */}
  //   </>
  // );
};
